import React from "react";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Main from "./Components/Main";

function App() {
  return <Main />;
}

export default App;
